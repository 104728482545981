import { differenceInYears } from 'date-fns/differenceInYears';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';

import { validUSPhoneNumberRegex } from '@/constants';

// TODO - use Treez Config
const minAge = parseInt(process?.env?.NEXT_PUBLIC_MIN_AGE_ALLOWED ?? '21');

const isFirstNameRequired =
  process?.env?.NEXT_PUBLIC_KIOSK_FIRST_NAME_REQUIRED === 'true';
const isLastNameRequired =
  process?.env?.NEXT_PUBLIC_KIOSK_LAST_NAME_REQUIRED === 'true';
const isEmailRequired =
  process?.env?.NEXT_PUBLIC_KIOSK_EMAIL_REQUIRED === 'true';
const isPhoneRequired =
  process?.env?.NEXT_PUBLIC_KIOSK_PHONE_REQUIRED === 'true';
const isDobRequired = process?.env?.NEXT_PUBLIC_KIOSK_DOB_REQUIRED === 'true';

export const fieldsValidationRules = [
  ...(isFirstNameRequired
    ? [
        {
          field: 'first_name',
          message: 'Enter your first name.',
          method: 'isEmpty',
          validWhen: false,
        },
      ]
    : []),
  {
    args: [{ min: 1 }],
    field: 'first_name',
    message: 'First name must have at least one character',
    method: 'isLength',
    validWhen: true,
  },
  ...(isLastNameRequired
    ? [
        {
          field: 'last_name',
          message: 'Enter your last name.',
          method: 'isEmpty',
          validWhen: false,
        },
      ]
    : []),
  {
    args: [{ min: 1 }],
    field: 'last_name',
    message: 'Last name must have at least one character',
    method: 'isLength',
    validWhen: true,
  },
  ...(isPhoneRequired
    ? [
        {
          field: 'phone_number',
          message: 'Enter your phone number.',
          method: 'isEmpty',
          validWhen: false,
        },
      ]
    : []),
  {
    field: 'phone_number',
    message: 'The phone format is incorrect.',
    method: (phone: string) => {
      return RegExp(/\+1 \(\d{3}\) \d{3}-\d{4}$/).test(phone);
    },
    validWhen: true,
  },
  {
    field: 'phone_number',
    message: 'Phone number must be a valid US number.',
    method: (phone: string) => {
      return RegExp(validUSPhoneNumberRegex).test(phone);
    },
    validWhen: true,
  },
  ...(isEmailRequired
    ? [
        {
          field: 'email',
          message: 'Enter your email.',
          method: 'isEmpty',
          validWhen: false,
        },
      ]
    : []),
  {
    field: 'email',
    message: 'The email format is incorrect.',
    method: 'isEmail',
    validWhen: true,
  },
  {
    field: 'date_of_birth',
    message: 'The birthdate format is incorrect.',
    method: 'isDate',
    validWhen: true,
  },
  ...(isDobRequired
    ? [
        {
          field: 'date_of_birth',
          message: 'Enter your date of birth.',
          method: 'isEmpty',
          validWhen: false,
        },
      ]
    : []),
  {
    field: 'date_of_birth',
    message: `Should be at least ${minAge} years old.`,
    method: current => {
      return differenceInYears(new Date(), new Date(current)) >= minAge;
    },
    validWhen: true,
  },
  {
    field: 'date_of_birth',
    message: 'Invalid date',
    method: current => {
      if (!current) {
        return true;
      }
      const parsedDate = parse(current, 'P', new Date());
      const isValidDate = isValid(parsedDate);
      return isValidDate;
    },
    validWhen: true,
  },
];

interface IUseKioskAccountFormRulesResponse {
  isFirstNameRequired: boolean;
  isLastNameRequired: boolean;
  isEmailRequired: boolean;
  isPhoneRequired: boolean;
  isDobRequired: boolean;
  formRules: any[];
}

const useKioskAccountFormRules = (): IUseKioskAccountFormRulesResponse => ({
  isFirstNameRequired,
  isLastNameRequired,
  isEmailRequired,
  isPhoneRequired,
  isDobRequired,
  formRules: fieldsValidationRules,
});

export { useKioskAccountFormRules };
